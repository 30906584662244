import { CriteriaModel, ExternalAuthKeyRequest, ExternalAuthKeyResponse, UserResponse } from "../models/authModel";
import axiosInstance from "./axiosConfig";

const externalAuthKeyGetListPath = '/externalauthkey/getlist';
const externalAuthKeySavePath = '/externalauthkey/save';

export const externalAuthKeyGetList = async (data: CriteriaModel): Promise<ExternalAuthKeyResponse> => {
  try {
    const response = await axiosInstance.post(externalAuthKeyGetListPath, data);
    return response.data;
  } catch (error) {
    console.error('externalAuthKeyGetList failed:', error);
    throw error;
  }
};

export const externalAuthKeySave = async (request: ExternalAuthKeyRequest): Promise<ExternalAuthKeyResponse> => {
  try {
    const response = await axiosInstance.post(externalAuthKeySavePath, request);
    return response.data;
  } catch (error) {
    console.error('externalAuthKeySave failed:', error);
    throw error;
  }
};