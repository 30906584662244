import { userGetList } from '../../friday/services/userService';
import { AppModel, UserModel } from '../../friday/models/authModel';
import GenericTable from './GenericTable';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      mode: 'light', // or 'dark'
    },
  });

const appColumns = [
  {
    accessorKey: 'id',
    header: 'ID',
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'firstName',
    header: 'FirstName',
  },
  {
    accessorKey: 'lastName',
    header: 'LastName',
  },
  {
    accessorKey: 'status',
    header: 'Status',
  },
  {
    accessorKey: 'createDate',
    header: 'Created Date',
  }
];

export default function UserTable () {
  return (
    <ThemeProvider theme={theme}>
        <GenericTable<UserModel>
            getServiceCall={userGetList}
            columns={appColumns}
        />
    </ThemeProvider>
  );
};
