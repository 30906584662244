import { APP_ID } from "../configs/constant";
import { LoginResponse } from "../models/authModel";
import { LoginRequest } from "../models/authModel";
import axiosInstance from "./axiosConfig";

const authLoginPath = '/auth/login';

export const login = async (data: LoginRequest): Promise<LoginResponse> => {
  try {
    const response = await axiosInstance.post(authLoginPath, data, {
        headers: {
          'AppId': APP_ID,
          'IsMain': true
        },
      });
    return response.data;
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
};
