// Chakra imports
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from '../../components/card/Card.js';
import AppsCard from "./AppsCard.js";
// Assets
// Custom components
import React, { useEffect, useState } from "react";
import { appGetList, appSaveCreateNewApp } from "../../friday/services/appService";
import { AppModel, CreateNewAppRequest, CriteriaModel, FormField, SortType } from "../models/authModel";
import { useGlobalContext } from '../utils/globalContext';
import DynamicFormPopup from "./DynamicFormPopup";

export default function Projects(props) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { setAppListData, appListData, setLoading } = useGlobalContext()
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
 
  const formFields: FormField[] = [
    { name: 'name', label: 'Name', type: 'text' },
    { name: 'description', label: 'Description', type: 'text' },
    { name: 'url', label: 'Url', type: 'text' }
  ];

  const handleSave = (values: { [key: string]: any }) => {
    onSaveCreateNewApp(values)
  };

  const refreshData = () => {
    onFetch();
  }

  const onSaveCreateNewApp = async (values: any) => {
    try {
      const request: CreateNewAppRequest = { data: 
        [{ name: values?.name, description: values?.description, url: values?.url }] 
      };
      setLoading(true);
      const response = await appSaveCreateNewApp(request);
      if (response?.isSuccess) {
        refreshData();
        setIsPopupOpen(false);
      }
    } catch (error) {
      alert(`onSaveCreateNewApp failed: ${error.message || 'Unknown error occurred.'}`);
    }
    finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    onFetch();
  }, []);

  const onFetch = async () => {
    try {
      const request: CriteriaModel = { page: 0, pageSize: 0, sorts: { field: 'createDate', type: SortType.Descending } };
      // @ts-ignore
      const response = await appGetList(request);
      if (response.isSuccess) {
        let models = response.data
        setAppListData(models)
      }
    } catch (error) {
      console.error('appGetList failed.');
    }
  };

  const onClickAddNew = () => {
    setIsPopupOpen(true)
  }

  return (<div>
    <DynamicFormPopup
      fields={formFields}  // error line
      isOpen={isPopupOpen}
      title="Yeni Uygulama Ekle"
      onSave={handleSave}
      onCancel={handleCancel}
    />
    <Card mb={{ base: "0px", "2xl": "20px" }}>
      <Flex direction={"row"}>
        <Text
          color={textColorPrimary}
          fontWeight='bold'
          fontSize='2xl'
          mt='10px'
          mb='4px'>
          Tüm Uygulamalar
        </Text>
        <div onClick={() => onClickAddNew()} style={{ marginLeft: "auto", cursor: "pointer" }}>
          <Text
            border={"1px solid"}
            borderRadius={"20px"}
            paddingLeft={"12px"}
            paddingRight={"12px"}
            color={textColorPrimary}
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.2)"
            fontWeight='bold'
            fontSize='2xl'
            mt='10px'
            mb='4px'>
            + Yeni Ekle
          </Text>
        </div>
      </Flex>
      <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        Yönetim sistemini kullabilmek için öncelikle yeni bir uygulama ekleyin. <br></br>
        Başka sayfaları veya sistemi kullanabilmek için uygulama sağ üstte bulunan combodan uygulama seçilmesi gerekir.
      </Text>
      {appListData?.map((item: AppModel) => (
        <AppsCard
          boxShadow={cardShadow}
          mb='20px'
          //image={Project1}
          ranking='1'
          desc={item.description}
          date={item.createDate}
          id={item.id}
          link='#'
          title={item.name}
        />
      ))}
    </Card>
    </div>
  );
}
