import React, {useState, useEffect} from "react";

// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";
import { useGlobalContext } from "friday/utils/globalContext";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH_ADMIN_DEFAULT } from "friday/configs/constant";

export function SidebarBrand() {
  const navigate = useNavigate();
  const { isLightColor } = useGlobalContext()
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");
  let color = isLightColor ? "#1B254B" : "white"

  return (
    <Flex align='center' direction='column'>
      <Flex cursor={"pointer"} onClick={() => navigate(ROUTE_PATH_ADMIN_DEFAULT)} align='center' direction='row'>
          <p style={{ fontSize: '32px', fontWeight: '500', color: color }}>Friday</p>
          <p style={{ fontSize: '40px', fontWeight: '800', color: color }}> API</p>
      </Flex>
      {/* <HorizonLogo h='26px' w='175px' my='32px' color={logoColor} /> */}
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
