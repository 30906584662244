import { Box } from "@chakra-ui/react";
import { useState } from "react";
import UserTable from "./UserTable";
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from '../../components/card/Card.js';

export default function Users() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  const onClickAddNew = () => {
    setIsPopupOpen(true)
  }

  return (
  <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
  <div>
    <Card mb={{ base: "0px", "2xl": "20px" }}>
      <Flex direction={"row"}>
        <Text
          color={textColorPrimary}
          fontWeight='bold'
          fontSize='2xl'
          mt='10px'
          mb='4px'>
          Kullanıcılar
        </Text>
        <div onClick={() => onClickAddNew()} style={{ marginLeft: "auto", cursor: "not-allowed", opacity: 0.5 }}>
          <Text
            border={"1px solid"}
            borderRadius={"20px"}
            paddingLeft={"12px"}
            paddingRight={"12px"}
            color={textColorPrimary}
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.2)"
            fontWeight='bold'
            fontSize='2xl'
            mt='10px'
            mb='4px'>
            + Yeni Ekle
          </Text>
        </div>
      </Flex>
      <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        Uygulamaya kayıt olmuş kullanıcıları listeler
      </Text>
    </Card>
    <UserTable />
  </div>
  </Box>);
}
