export const mainAuthToken = 'mainAuthToken';
export const authToken = 'authToken';
export const appId = 'AppId';
export const colorMode = 'chakra-ui-color-mode'

export const SetLocalStorageData = (key: string, value: string) => {
    localStorage.setItem(key, value);
}

export const GetLocalStorageData = (key: string) => {
    return localStorage.getItem(key);
}