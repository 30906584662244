function getEnumValueByName<T extends Record<string, any>>(
    enumObj: T,
    enumNames: Record<T[keyof T], string>,
    name: string
): T[keyof T] | undefined {
    return Object.entries(enumNames).find(([, v]) => v === name)?.[0] as T[keyof T] | undefined;
}

function getEnumNumberValueByName<T extends Record<string, any>>(
    enumObj: T,
    enumNames: Record<T[keyof T], string>,
    name: string
): number | undefined {
    let result = getEnumValueByName(enumObj, enumNames, name);
    return result ? parseInt(result.toString()) : undefined;
}


export { getEnumValueByName, getEnumNumberValueByName };