// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  Select,
} from '@chakra-ui/react';
// Custom Components
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
// Assets
import navImage from 'assets/img/layout/Navbar.png';
import { MdNotificationsNone, MdInfoOutline } from 'react-icons/md';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import { FaEthereum } from 'react-icons/fa';
import routes from 'routes';
import { useGlobalContext } from '../../friday/utils/globalContext';
import { AppModel } from '../../friday/models/authModel'
import { SetLocalStorageData } from '../../friday/utils/localStorageHelper';
import { authToken } from '../../friday/utils/localStorageHelper';
import { appGetSelectedApp } from '../../friday/services/appService';
import { GUIDEMPTY, ROUTE_PATH_ADMIN_APPS } from '../../friday/configs/constant';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH_AUTH_SIGNIN } from '../../friday/configs/constant';


export default function HeaderLinks(props) {
  const navigate = useNavigate();
  const { setLoading, setIsLightColor, isLightColor, appListData, setSelectedApp, selectedApp } = useGlobalContext();
  const { secondary } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  const navbarIcon = useColorModeValue('gray.400', 'white');
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.700', 'brand.400');
  const ethColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const ethBox = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );
  let colorCode = isLightColor ? 'red' : 'grey';
  const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');

  useEffect(() => {
    setIsLightColor(colorMode === 'light');
  }, [colorMode]);

  const onClickSelectApp = (appId) => {
    setLoading(true);
    let selectedAppFromCombo = appListData.find(app => app.id === appId);
    if (appId != GUIDEMPTY) {
      onGetSelectedAppCall(selectedAppFromCombo)
    }
  };

  const onGetSelectedAppCall = async (selectedAppFromCombo) => {
    try {
      // @ts-ignore
      const response = await appGetSelectedApp(selectedAppFromCombo?.id);
      if (response.isSuccess) {
        let model = response?.data
        if (model != null) {
          setSelectedApp(selectedAppFromCombo);
          SetLocalStorageData(authToken, model.token);
          setLoading(false);
          navigate(ROUTE_PATH_ADMIN_APPS);
        }
        else {
          alert('Uygulama seçilemedi')
        }
      }
    } 
    catch (error) {
      console.error('appGetList failed.');
    }
    finally {
      setLoading(false);
    }
  };

  let appListDataWithDefault = [...appListData, { id: GUIDEMPTY, name: 'Uygulama Seçiniz' }];

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      <SearchBar
        mb={() => {
          if (secondary) {
            return { base: '10px', md: 'unset' };
          }
          return 'unset';
        }}
        me="10px"
        borderRadius="30px"
      />
      <SidebarResponsive routes={routes} />
      <Button
        variant="no-hover"
        bg="transparent"
        p="0px"
        minW="unset"
        minH="unset"
        h="18px"
        w="max-content"
        onClick={toggleColorMode}
      >
        <Icon
          me="10px"
          h="18px"
          w="18px"
          color={navbarIcon}
          as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
        />
      </Button>
      <Button
        variant="no-hover"
        bg="transparent"
        p="0px"
        minW="unset"
        minH="unset"
        h="18px"
        w="max-content"
      >
        <Select
          fontSize='sm'
          variant='subtle'
          defaultValue={GUIDEMPTY}
          value={selectedApp?.id}
          placeholder='Uygulama Seçiniz'
          width='unset'
          onChange={(e) => onClickSelectApp(e.target.value)}
          fontWeight='700'>
            {appListData?.map((x: AppModel) => (
              <option key={x.id} value={x.id}>{x.name}</option>
            ))}
        </Select>
      </Button>
      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: 'pointer' }}
            color="white"
            name="Mr. Stark"
            bg="#11047A"
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; Hey, Mr.Stark
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              color={colorCode}
              borderRadius="8px"
              px="14px"
            >
              <Text onClick={() => navigate(ROUTE_PATH_AUTH_SIGNIN)} color={colorCode} fontSize="sm">Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
