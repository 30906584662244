// @ts-ignore
import React , { useState, useEffect } from "react";
// @ts-ignore
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  // @ts-ignore
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
// @ts-ignore
import { HSeparator } from "components/separator/Separator";
// @ts-ignore
import DefaultAuth from "layouts/auth/Default";
// Assets
// @ts-ignore
import illustration from "assets/img/auth/auth4.png";
// @ts-ignore
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { login } from "../../../friday/services/authService";
import { mainAuthToken } from "../../../friday/utils/localStorageHelper";
import { SetLocalStorageData } from "../../../friday/utils/localStorageHelper";
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH_ADMIN } from "../../../friday/configs/constant";
import { useGlobalContext } from "../../../friday/utils/globalContext";

function SignIn() {
  const { setLoading } = useGlobalContext();
  const navigate = useNavigate(); // Initialize the navigate function
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // @ts-ignore
  const [rememberMe, setRememberMe] = useState(false);
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const onClickSignInButton = async () => {
    try {
      const request = { email, password };
      // @ts-ignore
      setLoading(true);
      const response = await login(request);
      if (response?.isSuccess && response?.data?.token) {
        SetLocalStorageData(mainAuthToken, response?.data?.token)
        navigate(ROUTE_PATH_ADMIN); // Navigate to the "/admin" page after login
      }
    } catch (error) {
      alert('Login failed. Please check your credentials.');
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign In
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='mail@friday.com'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              mb='24px'
              fontWeight='500'
              size='lg'
            />
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Password
            </FormLabel>
            <InputGroup size='md'>
              <Input
                isRequired={true}
                fontSize='sm'
                placeholder='Min. 8 characters'
                mb='24px'
                size='lg'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={show ? "text" : "password"}
                variant='auth'
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Button
              onClick={onClickSignInButton}
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'>
              Sign In
            </Button>
          </FormControl>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
