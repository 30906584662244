// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "../../components/card/Card.js";
import React from "react";
// Assets
import { MdEdit } from "react-icons/md";
import { formatDateYearMonth } from "../utils/dateFormatter";
import { useGlobalContext } from "../utils/globalContext";

export default function AppsCard(props) {
  const { selectedApp } = useGlobalContext();
  const { title, ranking, date, link, id, desc, image, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const bg = useColorModeValue("white", "navy.700");
  return (
    <Card bg={bg} {...rest} p='14px'>
      <Flex align='center' direction={{ base: "column", md: "row" }}>
        <Box mt={{ base: "10px", md: "0" }}>
          <Text
            color={textColorPrimary}
            fontWeight='500'
            fontSize='md'
            mb='4px'>
            {title} #{selectedApp?.id === id ? " (Active)" : ""}
          </Text>
          <Text
            fontWeight='500'
            color={textColorSecondary}
            fontSize='sm'
            me='4px'>
            {desc}  #{formatDateYearMonth(date)} •{" "}
            <Link fontWeight='500' color={brandColor} href={link} fontSize='sm'>
              Detay
            </Link>
          </Text>
          <Text
            fontWeight='500'
            color={textColorSecondary}
            fontSize='sm'
            me='4px'>
            AppId: <b>{id}</b>
          </Text>
        </Box>
        <Link
          href={link}
          variant='no-hover'
          me='16px'
          ms='auto'
          p='0px !important'>
          <Icon as={MdEdit} color='secondaryGray.500' h='18px' w='18px' />
        </Link>
      </Flex>
    </Card>
  );
}
