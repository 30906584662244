import { userGetList } from '../../friday/services/userService';
import { AppModel, AuthTypeEnumNames, CriteriaModel, DeviceTypeEnumNames, ExternalAuthKeyModel, UserModel } from '../../friday/models/authModel';
import GenericTable from './GenericTable';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { externalAuthKeyGetList } from '../../friday/services/externalAuthKeyService';

const theme = createTheme({
    palette: {
      mode: 'light', // or 'dark'
    },
  });

const gridColumns = [
  {
    accessorKey: 'id',
    header: 'ID',
  },
  {
    accessorKey: 'authTypeValue',
    header: 'AuthType',
  },
  {
    accessorKey: 'deviceTypeValue',
    header: 'DeviceType',
  },
  {
    accessorKey: 'clientId',
    header: 'ClientId',
  },
  {
    accessorKey: 'secret',
    header: 'Secret',
  },
  {
    accessorKey: 'createDate',
    header: 'Created Date',
  }
];

export const externalAuthKeyGetListEvent = async (data: CriteriaModel) => {
  const response = await externalAuthKeyGetList(data); 
  response.data.forEach(item => {
    item.authTypeValue = AuthTypeEnumNames[item.authType];
    item.deviceTypeValue = DeviceTypeEnumNames[item.deviceType];
  });
  return response;
};

export default function ExternalAuthTable () {
  return (
    <ThemeProvider theme={theme}>
        <GenericTable<ExternalAuthKeyModel>
            getServiceCall={externalAuthKeyGetListEvent}
            columns={gridColumns}
        />
    </ThemeProvider>
  );
};
