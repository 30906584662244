import { Box } from "@chakra-ui/react";
import { useState } from "react";
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from '../../components/card/Card.js';
import ExternalAuthTable from "./ExternalAuthTable";
import DynamicFormPopup from "./DynamicFormPopup";
import { AuthType, AuthTypeEnumNames, DeviceType, DeviceTypeEnumNames, ExternalAuthKeyRequest, FormField } from "../../friday/models/authModel";
import { useGlobalContext } from "../../friday/utils/globalContext";
import { externalAuthKeySave } from "../../friday/services/externalAuthKeyService";
import { getEnumNumberValueByName } from "../../friday/utils/helper";

const formFields: FormField[] = [
  { name: 'authType', label: 'AuthType', type: 'select', options: [AuthTypeEnumNames[AuthType.Google] , AuthTypeEnumNames[AuthType.Apple]] },
  { name: 'deviceType', label: 'DeviceType', type: 'select', options: [DeviceTypeEnumNames[DeviceType.Web], DeviceTypeEnumNames[DeviceType.Android], DeviceTypeEnumNames[DeviceType.IOS], DeviceTypeEnumNames[DeviceType.None]] },
  { name: 'clientId', label: 'ClientId', type: 'text' },
  { name: 'secret', label: 'Secret', type: 'text' }
];

export default function ExternalAuths() {
  const { setAppListData, appListData, setLoading } = useGlobalContext()
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  const onSaveExternalAuthKey = async (values: any) => {
    try {
      const request: ExternalAuthKeyRequest = { data: 
        [{id: null,
          appId: null,
          createDate: null,
          updateDate: null,
          authType: getEnumNumberValueByName(AuthType, AuthTypeEnumNames, values?.authType),
          deviceType: getEnumNumberValueByName(DeviceType, DeviceTypeEnumNames, values?.deviceType), 
          clientId: values?.clientId, 
          secret: values?.secret
        }] 
      };
      setLoading(true);
      const response = await externalAuthKeySave(request);
      if (response?.isSuccess) {
        refreshData();
        setIsPopupOpen(false);
      }
    } catch (error) {
      alert(`onSaveExternalAuthKey failed: ${error.message || 'Unknown error occurred.'}`);
    }
    finally {
      setLoading(false);
    }
  };

  const onClickAddNew = () => {
    setIsPopupOpen(true)
  }

  const handleSave = (values: { [key: string]: any }) => {
    onSaveExternalAuthKey(values)
  };

  const handleCancel = () => {
    setIsPopupOpen(false);
  };

  const refreshData = () => {
    //onFetch();
  }

  return (
  <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
  <div>
    <DynamicFormPopup
      fields={formFields}  // error line
      isOpen={isPopupOpen}
      title="Yeni External Auth Ekle"
      onSave={handleSave}
      onCancel={handleCancel}
    />
    <Card mb={{ base: "0px", "2xl": "20px" }}>
      <Flex direction={"row"}>
        <Text
          color={textColorPrimary}
          fontWeight='bold'
          fontSize='2xl'
          mt='10px'
          mb='4px'>
          External Auth
        </Text>
        <div onClick={() => onClickAddNew()} style={{ marginLeft: "auto", cursor: "pointer"}}>
          <Text
            border={"1px solid"}
            borderRadius={"20px"}
            paddingLeft={"12px"}
            paddingRight={"12px"}
            color={textColorPrimary}
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.2)"
            fontWeight='bold'
            fontSize='2xl'
            mt='10px'
            mb='4px'>
            + Yeni Ekle
          </Text>
        </div>
      </Flex>
      <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        Google/Apple gibi harici uygulama signin keyleri eklenir ve listelenir.
      </Text>
    </Card>
    <ExternalAuthTable />
  </div>
  </Box>);
}
