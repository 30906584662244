import { CriteriaModel, UserResponse } from "../models/authModel";
import axiosInstance from "./axiosConfig";

const userGetListPath = '/user/getList';

export const userGetList = async (data: CriteriaModel): Promise<UserResponse> => {
  try {
    const response = await axiosInstance.post(userGetListPath, data);
    return response.data;
  } catch (error) {
    console.error('userGetList failed:', error);
    throw error;
  }
};