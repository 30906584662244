import axios from 'axios';
import { API_BASE_URL, ROUTE_PATH_AUTH_SIGNIN } from '../configs/constant'; // Import the base URL constant
import { authToken, mainAuthToken } from '../utils/localStorageHelper';
import { GetLocalStorageData } from '../utils/localStorageHelper';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // If you need to include cookies for authentication
});

const getToken = (isMain: boolean) => {
  let token : string | null = '';
  if (isMain) {
    token = GetLocalStorageData(mainAuthToken);
  }
  else {
    token = GetLocalStorageData(authToken);
  }
  return token;
}

// Add a request interceptor (optional)
axiosInstance.interceptors.request.use(
  (config) => {
    // Attach authorization token if present
    let isMain = config.headers['IsMain'];
    let token = getToken(isMain);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
  (response) => {
    // If the response is successful, just return it
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Redirect to login page if status is 401 Unauthorized
      window.location.href = ROUTE_PATH_AUTH_SIGNIN; // Change this to your login page route
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
