import { useEffect, useState } from 'react';
import { MaterialReactTable, type MRT_ColumnDef, type MRT_ColumnFiltersState, type MRT_PaginationState, type MRT_SortingState } from 'material-react-table';
import { CriteriaModel } from '../../friday/models/authModel';

interface BaseListResponse<T> {
  isSuccess: boolean;
  messages?: MessageModel[];
  data?: T[];
  totalCount?: number;
}

interface MessageModel {
  type: string;
  text: string;
}

type GenericTableProps<T> = {
  getServiceCall: (params: CriteriaModel) => any; // Function to call the API
  columns: MRT_ColumnDef<T>[];
};

function GenericTable<T>({ getServiceCall, columns }: GenericTableProps<T>) {
  const [data, setData] = useState<T[]>([]);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  // Table state
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      // Default request parameters
      const params: CriteriaModel = {
        page: pagination.pageIndex || 0, // Default to 0 if pageIndex is not defined
        pageSize: pagination.pageSize || 0, // Default to 0 if pageSize is not defined
        //filters: columnFilters,
        //sorts: sorting.length > 0 ? sorting[0] : undefined, // Assuming one sort at a time
      };

      try {
        const response = await getServiceCall(params); // Call the service function with the default params
        if (response.isSuccess) {
          setData(response.data ?? []);
          setRowCount(response.totalCount ?? 0);
          setIsError(false);
          setErrorMessage(null);
        } else {
          setIsError(true);
          setErrorMessage(
            response.messages?.map((msg) => msg.text).join('; ') || 'Failed to load data.'
          );
        }
      } catch (error) {
        setIsError(true);
        setErrorMessage('An unexpected error occurred.');
        console.error(error);
      } finally {
        setIsLoading(false);
        setIsRefetching(false);
      }
    };

    fetchData();
  }, [columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting, getServiceCall]);

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enableRowSelection={true}
      manualFiltering={true}
      manualPagination={true}
      manualSorting={true}
      muiToolbarAlertBannerProps={isError ? { color: 'error', children: errorMessage || 'Error loading data' } : undefined}
      onColumnFiltersChange={setColumnFilters}
      onGlobalFilterChange={setGlobalFilter}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      rowCount={rowCount}
      state={{
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isRefetching,
        sorting,
      }}
    />
  );
}

export default GenericTable;
