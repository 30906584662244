import React, { useState } from 'react';
import './DynamicFormPopupStyle.css';
import { DynamicFormPopupProps } from '../../friday/models/authModel';

const DynamicFormPopup: React.FC<DynamicFormPopupProps> = ({
  fields,
  isOpen,
  title,
  onSave,
  onCancel,
}) => {
  const [formData, setFormData] = useState<{ [key: string]: any }>({});

  const handleChange = (name: string, value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = () => {
    onSave(formData);
  };

  if (!isOpen) return null;

  return (
    <div className="dynamic-form-popup-overlay">
      <div className="dynamic-form-popup-container dynamic-form-popup">
        <h2 className="dynamic-form-title">{title}</h2>
        <form>
          {fields.map((field) => (
            <div key={field.name} className="form-group">
              <label>{field.label}</label>
              {field.type === 'text' && (
                <input
                  type="text"
                  value={formData[field.name] || ''}
                  onChange={(e) => handleChange(field.name, e.target.value)}
                />
              )}
              {field.type === 'number' && (
                <input
                  type="number"
                  value={formData[field.name] || ''}
                  onChange={(e) => handleChange(field.name, e.target.value)}
                />
              )}
              {field.type === 'select' && (
                <select
                  value={formData[field.name] || ''}
                  onChange={(e) => handleChange(field.name, e.target.value)}
                >
                  <option value="">Select</option>
                  {field.options?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              )}
            </div>
          ))}
        </form>
        <div className="button-group">
          <button type="button" className="save-button" onClick={handleSave}>
            Save
          </button>
          <button type="button" className="cancel-button" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DynamicFormPopup;
