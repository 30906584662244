import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import {} from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import {
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useState, useEffect } from 'react';
import { ROUTE_PATH_ADMIN_APPS } from 'friday/configs/constant';
import { useGlobalContext } from 'friday/utils/globalContext';
import LoadingScreen from 'friday/components/Loading';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH_AUTH_SIGNIN } from 'friday/configs/constant';
import SignInCentered from './views/auth/signIn';
import { GetLocalStorageData, mainAuthToken } from './friday/utils/localStorageHelper';
// Chakra imports

export default function Main() {
  const navigate = useNavigate();
  const { loading, appListData, selectedApp } = useGlobalContext();
  // eslint-disable-next-line
  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  useEffect(() => {
    let token = GetLocalStorageData(mainAuthToken);
    if ((appListData.length < 1 || selectedApp === null || selectedApp === undefined) && 
        window.location.pathname !== ROUTE_PATH_AUTH_SIGNIN &&
        window.location.pathname !== ROUTE_PATH_ADMIN_APPS &&
        token != null) {
          navigate(ROUTE_PATH_ADMIN_APPS); 
          alert('Lütfen sağ üstteki seçenekten uygulama seçiniz.');
    }
  }, [appListData, window.location.pathname]);

  return (
    <ChakraProvider theme={currentTheme}>
      {loading && <LoadingScreen />}
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route
          path="admin/*"
          element={
            <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        />
        <Route path="/" element={<Navigate to={ROUTE_PATH_ADMIN_APPS} replace />} />
      </Routes>
    </ChakraProvider>
  );
}
