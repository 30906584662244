import { APP_ID } from "../configs/constant";
import { AppResponse, CreateNewAppRequest, CreateNewAppResponse, CriteriaModel, LoginResponse, SelectedAppResponse } from "../models/authModel";
import axiosInstance from "./axiosConfig";

const appGetListPath = '/app/getList';
const appGetSelectedAppPath = '/app/getselectedapp';
const appCreateNewAppPath = '/app/createnewapp';

export const appGetList = async (data: CriteriaModel): Promise<AppResponse> => {
  try {
    const response = await axiosInstance.post(appGetListPath, data, {
        headers: {
          'AppId': APP_ID,
          'IsMain': true
        },
      });
    return response.data;
  } catch (error) {
    console.error('App Get List failed:', error);
    throw error;
  }
};

export const appGetSelectedApp = async (appId: string): Promise<SelectedAppResponse> => {
  try {
    const response = await axiosInstance.get(appGetSelectedAppPath, {
      params: {
        appId, // appId is sent as a query parameter
      },
      headers: {
        'AppId': APP_ID, 
        'IsMain': true, 
      },
    });
    return response.data;
  } catch (error) {
    console.error('appGetSelectedApp failed:', error);
    throw error;
  }
};

export const appSaveCreateNewApp = async (request: CreateNewAppRequest): Promise<CreateNewAppResponse> => {
  try {
    const response = await axiosInstance.post(appCreateNewAppPath, request, {
        headers: {
          'AppId': APP_ID,
          'IsMain': true
        },
      });
    return response.data;
  } catch (error) {
    console.error('appSaveCreateNewApp failed:', error);
    throw error;
  }
};
